'use client';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { truckMapConfig } from 'truckMapConfig';
import socialProfileConfig from '../DefaultSeo/social.config';
import softwareAppConfig from '../DefaultSeo/software.config';

export const ClientJsonLdScripts = () => {
  const pathname = usePathname();
  const { baseUrl } = truckMapConfig;
  const isPlaceRelatedPage = pathname.startsWith('/place');

  const jsonLdScripts = [
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: baseUrl,
      logo: `${baseUrl}/images/logo.svg`
    },
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      ...socialProfileConfig
    },
    ...(!isPlaceRelatedPage
      ? [
          {
            '@context': 'https://schema.org',
            '@type': 'SoftwareApplication',
            ...softwareAppConfig
          }
        ]
      : [])
  ];

  return (
    <>
      {jsonLdScripts.map((script, index) => (
        <Script
          key={index}
          id={`jsonld-${index}`}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(script) }}
        />
      ))}
    </>
  );
};
