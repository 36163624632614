'use client';
import Script from 'next/script';

import { useEffect } from 'react';
import GTM from 'react-gtm-module-custom-domain';
import { truckMapConfig } from 'truckMapConfig';

const { apiKeys, env, isClientSideRender, url, gtmFile, isSearchBot } = truckMapConfig;

export const GTMScript = () => {
  useEffect(() => {
    if (env === 'production' && isClientSideRender && !isSearchBot) {
      GTM.initialize({ gtmId: apiKeys.gtm.id, customURL: `${url}${gtmFile}` });
    }
  }, []);

  return (
    <Script id="gtag-init" strategy="afterInteractive">
      {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-7R210JXB0P');
        `}
    </Script>
  );
};
