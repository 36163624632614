'use client';
import { Analytics } from '@vercel/analytics/react';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { Toaster } from 'react-hot-toast';

export function ClientLayoutComponents() {
  return (
    <>
      <ProgressBar
        height="2px"
        color="#3796FE"
        options={{ showSpinner: false }}
        shallowRouting
        delay={0}
      />
      <Toaster />
      <Analytics />
    </>
  );
}
