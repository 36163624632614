'use client';
import { useParams } from 'next/navigation';
import Script from 'next/script';
import { truckMapConfig } from 'truckMapConfig';

export const ClientPolyfillScript = () => {
  const params = useParams();
  const locale = (params?.locale as string) || truckMapConfig.i18n.defaultLocale;
  const [intlLocale] = locale.split('-');

  return (
    <Script
      strategy="beforeInteractive"
      src={`${truckMapConfig.staticCDN}/assets/js/polyfill/cdn/polyfill.js?features=${
        intlLocale ? `Intl.~locale.${intlLocale},` : ''
      }Object.keys`}
    />
  );
};
