'use client';
import { ClientJsonLdScripts } from 'components/AppHead/ClientJsonLdScripts';

export const ClientAppHead = () => {
  return (
    <head>
      <link rel="icon" href="/favicon.ico" />
      <ClientJsonLdScripts />
    </head>
  );
};
